.hero {
  padding-bottom: 22em;
  margin-bottom: -20em;
  text-align: center;
}

.heroContent {
  position: relative;
}

.rotatedBackground {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  top: -35%;
  left: -1rem;
  width: 130%;
  height: 100%;
  transform: rotate(-7deg);
  border-bottom-left-radius: 3rem;
  position: absolute;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.sparklesBackground {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("/sparkles.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.logo {
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
}
